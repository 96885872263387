function getTarget() {
    return process.env.REACT_APP_TARGET;
}

function isCordova() {
    return (getTarget() === 'cordova');
}

function isCordovaDebug() {
    return (getTarget() === 'mobile');
}

export { isCordova, isCordovaDebug };